/* eslint-disable jsx-a11y/alt-text */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { twJoin } from 'tailwind-merge';

import Image from 'components/Image';

import { getAdCssBackgroundColorProp } from 'lib/ads';
import { sendEvent } from 'lib/gtag';

import APP from 'constants/app';
import TOPBAR from 'constants/topbar';

const SCROLL_THRESHOLD_TO_PULL_UP_BANNER = 50;

/**
 * TopbarAd's image is normally the LCP element on every page if it exists.
 * This is not the case with our offer and blog pages, though. In these specific
 * pages there is a bigger image instead of the one on referenced by
 * this component. If on any of these pages, we skip preloading.
 *
 * TODO: would be better to come as a prop from
 * page's `getServerSideProps` instead of validating the router
 * so we keep this component page agnostic.
 */
const isTopbarAdImageLcpOnCurrentPage = (route) =>
  !route.startsWith('/oferta/') && !route.startsWith('/blog/');

const isDefaultBgColor = (bgcolor) => bgcolor.includes('#000000');

const toMobileImageWithDynamicSize = (mobileImage = '', size = 428) =>
  `https://i.promobit.com.br/ar/${size}/${mobileImage.split('/')[3]}`;

const toDescriptionWithCampaign = (description) => `Campanha - ${description}`;

const TopbarAd = ({ bgcolor, description, image, mobileImage, url }) => {
  const [isScrollPositionAtThreshold, setScrollPositionAtThreshold] =
    useState(true);
  const { route } = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < SCROLL_THRESHOLD_TO_PULL_UP_BANNER) {
        setScrollPositionAtThreshold(true);
        return;
      }

      if (
        window.scrollY > SCROLL_THRESHOLD_TO_PULL_UP_BANNER &&
        isScrollPositionAtThreshold
      ) {
        setScrollPositionAtThreshold(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrollPositionAtThreshold, setScrollPositionAtThreshold]);

  const onAnchorClick = () => {
    sendEvent({
      action: 'topbar_banner__click',
      category: TOPBAR.GA_EVENT_CATEGORY,
    });
  };

  const imageSharedProps = {
    alt: toDescriptionWithCampaign(description),
    priority: isTopbarAdImageLcpOnCurrentPage(route),
  };

  return (
    <div
      className={twJoin(
        'h-11 w-full lg:h-8',
        mobileImage ? 'block' : 'hidden lg:block'
      )}
      style={{
        background: isDefaultBgColor(bgcolor)
          ? '#FFFFFF'
          : getAdCssBackgroundColorProp(bgcolor),
      }}
    >
      <a
        className={twJoin(
          'z-40 mx-auto flex w-full justify-center lg:fixed',
          !isScrollPositionAtThreshold ? '-top-[10px]' : 0
        )}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onAnchorClick}
      >
        {mobileImage && (
          <Image
            className="block h-11 w-full max-w-[500px] md:rounded-5 lg:hidden"
            height={64}
            sizes="(max-width: 1024px) 428px, 1px"
            src={mobileImage}
            srcSet={`
            ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
            ${toMobileImageWithDynamicSize(mobileImage, 656)} 656w,
            ${toMobileImageWithDynamicSize(mobileImage, 800)} 800w
          `}
            width={428}
            {...imageSharedProps}
          />
        )}
        {image && (
          <Image
            className="hidden max-w-[1920px] rounded-5 object-contain lg:block lg:h-auto lg:w-auto"
            height={64}
            sizes="(max-width: 1024px) 1px, 1920px"
            src={image}
            srcSet={`${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w, ${image} 1920w`}
            width={375}
            {...imageSharedProps}
          />
        )}
      </a>
    </div>
  );
};

TopbarAd.propTypes = {
  bgcolor: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default TopbarAd;
